export default {
  methods: {
    getUsernameWithRole(username, user_is_staff) {
      if (username === null) {
        return "anonymous";
      }
      if (user_is_staff === true) {
        return `${username} <span class="author-role">(Staff)</span>`;
      } else {
        return `${username}`;
      }
    }
  }
};
